<template>
	<div class="dashboard-content" data-app>
		<!-- Content -->
		<div class="card">
			<div class="filters" style="padding: 10px 10px 0px 10px">
				<h3 class="text-center">Formulario de Solicitudes</h3>
				<v-row style="margin: 0px">
					<v-col cols="12" style="padding-top: 0px">
						<label>Filtrar por:</label>

						<v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
							<v-col cols="12" lg="11" md="10" sm="8">
								<v-row>
									<v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
										<v-text-field
											dense
											solo
											hide-details="auto"
											label="Búsqueda"
											v-model="filters.search"
										></v-text-field>
									</v-col>
									<v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
										<multiselect
											track-by="name"
											label="name"
											deselect-label=""
											select-label=""
											placeholder="Servicio"
											v-model="filters.service"
											:options="selectService"
											:multiple="false"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
										></multiselect>
									</v-col>
									<v-col cols="12" lg="3" md="3" sm="6" style="padding: 1px 2px 1px 2px">
										<multiselect
											track-by="id"
											label="name"
											deselect-label=""
											select-label=""
											placeholder="País"
											:disabled="!roles.includes('super_admin')"
											:custom-label="countryName"
											v-model="filters.country"
											:options="countries"
											:multiple="false"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
										></multiselect>
									</v-col>
									<v-col cols="12" lg="2" md="2" sm="6" style="padding: 1px 2px 1px 2px">
										<multiselect
											style="min-width: 80px"
											track-by="id"
											label="name"
											deselect-label=""
											select-label=""
											placeholder="Estatus"
											v-model="filters.status"
											:options="statusSelect"
											:multiple="false"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
										></multiselect>
									</v-col>
									<v-col cols="12" lg="3" md="4" sm="6" class="mt-3">
										<v-text-field
											type="date"
											hide-details="auto"
											label="Fecha inicial"
											v-model="filters.date.startAt"
										>
										</v-text-field>
									</v-col>
									<v-col cols="12" lg="3" md="4" sm="6" class="mt-3">
										<v-text-field
											type="date"
											hide-details="auto"
											label="Fecha final"
											v-model="filters.date.endAt"
										></v-text-field>
									</v-col>
								</v-row>
							</v-col>
							<v-btn
								color="#003a56"
								style="min-width: auto; position: absolute; right: 40px"
								dark
								title="Eliminar filtro"
								class="p-1"
								v-if="filterActivated"
								@click.prevent="
									filterActivated = false
									cleanFilters()
									getFormRequests()
									getChartData()
								"
								><v-icon dark> mdi-delete </v-icon>
							</v-btn>
							<v-btn
								color="#003a56"
								style="min-width: auto; position: absolute; right: 0px"
								title="Filtrar"
								dark
								class="p-1"
								@click.prevent="
									filterActivated = true
									getFormRequests()
									getChartData()
								"
							>
								<v-icon dark> mdi-filter </v-icon>
							</v-btn>
						</v-form>
					</v-col>
					<v-col cols="12" class="chart-box mt-4">
						<apexchart
							:key="index"
							:height="'100%'"
							type="bar"
							:options="chartOptions"
							:series="chartSeries"
					/></v-col>
				</v-row>
			</div>
			<simple-list
				:title="'Formulario de Solicitudes'"
				:dense="true"
				:headers="headers"
				:items="items"
				:totalItems="totalItems"
				:itemsPerPage="itemsPerPage"
				:loadingText="loadingText"
				:hideFooter="true"
				:multiSort="false"
				:loading="loading"
				:class="'mb-4'"
				:sortable="true"
				:page="page"
				:pages="pages"
				:extraButtoms="false"
				:showCreate="false"
				:showDelete="true"
				:buttonLink="'empresas/ficha'"
				:editIcon="'mdi-eye'"
				:editTooltip="'Ver Solicitud'"
				:orderOptions="orderOptions"
				@edit:item="handleEdit($event)"
				@delete:item="deleteItem($event)"
				@update:page="pagination($event)"
			/>
		</div>
		<modal name="crudModal" :height="'75%'" :width="'80%'" :adaptive="true">
			<div class="card" style="padding: 1rem; margin: 10px">
				<h3 class="text-center mb-4" v-if="flow == 'edit'">Detalles de la solicitud</h3>
				<span v-if="flow == 'edit' && item.created_at" class="mb-3"
					>Fecha de creación:
					{{
						createdAt.toLocaleDateString('es-VE', {
							weekday: 'long',
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
							timeZone: 'UTC',
						})
					}}. {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
				>
				<v-form
					ref="formCompany"
					v-model="valid"
					style="width: 100%"
					:disabled="disableForm"
				>
					<v-row class="mx-auto">
						<v-col cols="12" lg="3" md="3" sm="6">
							<v-row no-gutters>
								<span style="width: 30%">País:</span>
								<multiselect
									style="width: 70%"
									track-by="id"
									label="name"
									deselect-label=""
									select-label=""
									placeholder="País"
									:disabled="true"
									:custom-label="countryName"
									v-model="item.country"
									:options="countries"
									:multiple="false"
									:close-on-select="true"
									:clear-on-select="false"
									:preserve-search="false"
								></multiselect>
							</v-row>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12" v-if="item.company">
							<v-text-field
								label="Empresa asociada"
								v-model="item.company.name"
							></v-text-field
						></v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field label="Servicio" v-model="service"></v-text-field>
						</v-col>
						<v-col cols="12" lg="8" md="8" sm="12">
							<v-textarea
								label="Mensaje del interesado"
								rows="3"
								v-model="message"
							></v-textarea>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-select
								:items="statusOptions"
								item-text="name"
								item-value="code"
								v-model="status"
								label="Estatus"
							></v-select>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12" v-if="item.checker">
							<v-text-field
								label="Usuario ATC"
								v-model="item.checker.name"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="4" sm="12" v-if="item.checker">
							<v-text-field
								label="Fecha de atención"
								v-model="checkTime"
								type="date"
							></v-text-field>
						</v-col>
					</v-row>
					<h6 style="margin-bottom: 20px">Datos del usuario</h6>
					<v-row class="mx-0">
						<v-col cols="12" lg="4" md="6" sm="12" v-if="item.user">
							<v-text-field
								label="Usario registrado"
								v-model="item.user.fullname"
							></v-text-field
						></v-col>
						<v-col cols="12" lg="4" md="6" sm="12" v-if="!item.user">
							<v-text-field label="Nombre" v-model="item.userFullName"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field label="Email" v-model="email"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								v-mask="phoneMask"
								:rules="[rules.required, rules.maxNumber]"
								label="Teléfono"
								v-model="phone"
								type="text"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								label="Número de identificación"
								v-model="documentNumber"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field label="Empresa" v-model="businessName"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								label="Rubro de la empresa"
								v-model="businessType"
							></v-text-field>
						</v-col>
					</v-row>
				</v-form>
				<v-divider></v-divider>
				<v-row class="mx-0 justify-content-center">
					<v-col cols="12" lg="4" md="6" sm="12">
						<v-select
							:items="statusOptions"
							:item-text="'name'"
							:item-value="'code'"
							v-model="status"
							label="Marcar solicitud como:"
						></v-select>
					</v-col>
				</v-row>
			</div>
			<v-spacer></v-spacer>
			<v-col cols="12" style="margin-bottom: 20px; height: 10%">
				<div class="row">
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						v-if="flow == 'edit'"
						:disabled="!valid"
						color="#003a56"
						@click="saveItem()"
					>
						Guardar
					</v-btn>
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						color="#003a56"
						dark
						v-if="roles.includes('super-admin')"
						@click="disableForm = !disableForm"
					>
						{{ disableForm == true ? 'Habilitar Edición' : 'Desabilitar Edición' }}
					</v-btn>
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						color="#003a56"
						dark
						@click="$modal.hide('crudModal')"
					>
						Cancelar
					</v-btn>
				</div>
			</v-col>
		</modal>
	</div>
</template>

<script>
	const date = new Date()
	import SimpleList from 'Components/Listing/SimpleList.vue'

	export default {
		name: 'FormRequest',
		components: {
			SimpleList,
		},
		data() {
			return {
				config: {
					headers: {},
				},
				companies: [],
				loading: false,
				headers: [
					{ text: 'N°', value: 'id' },
					{ text: 'Fecha', value: 'dateText' },
					{ text: 'Usuario', value: 'userFullName' },
					{ text: 'Servicio de Interés', value: 'service' },
					{ text: 'Estatus', value: 'statusText' },
					{ text: 'Acciones', value: 'actions', align: 'center', width: '110px' },
				],
				statusOptions: [
					{ code: 'pending', name: 'Por atender' },
					{ code: 'rejected', name: 'Rechazada' },
					{ code: 'verified', name: 'Atendida' },
					{ code: 'canceled', name: 'Cancelada' },
				],
				selectService: [
					{ name: 'Todos' },
					{ name: 'Información de Franquicia o Franquicia Master' },
					{ name: 'Información acerca de Proveedores u Oferta Inmobiliaria' },
					{ name: 'Unirse a Asociaciones de Franquicias' },
					{ name: 'Servicios de Consultoría y/o Asesoría' },
					{ name: 'Contratar espacios publicitarios' },
					{
						name: 'Formar parte del Directorio y publicar o destacar tu negocio',
					},
					// { name: 'Anunciar en la revista Su Franquicia' },
				],
				filterActivated: false,
				orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
				orderOptions: [
					{ id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
					{ id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
					{ id: 3, name: 'Nombre', order: 'name', by: 'asc' },
					{ id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
				],
				filters: {
					date: {
						endAt: null,
						startAt: null,
					},
					search: '',
					service: null,
					status: null,
					country: null,
				},

				statusSelect: [
					{ id: null, name: 'Todas' },
					{ id: 'pending', name: 'Por atender' },
					{ id: 'verified', name: 'Atendidas' },
					{ id: 'rejected', name: 'Rechazadas' },
					{ id: 'canceled', name: 'Canceladas' },
				],
				extraButtoms: [
					{
						text: 'Vista previa',
						link: 'CompanyDetail',
						icon: 'mdi-eye',
						query: { name: 'slug', value: 'link' },
						typeLink: 'name',
					},
				],

				rules: {
					required: (v) => !!v || 'Requerido.',
					min: (v) => v.length >= 8 || '6 caracteres como mínimo',
					maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
				},
				phoneMask: [
					'+',
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
				],
				countries: [{ id: null, name: 'Todos' }],
				items: [],
				users: [],
				page: 1,
				pages: 1,
				item: {},
				totalItems: 0,
				itemsPerPage: 10,
				flow: '',
				userId: '',
				companyId: '',
				checkerId: '',
				name: '',
				lastname: '',
				documentNumber: '',
				service: '',
				businessName: '',
				businessType: '',
				message: '',
				email: '',
				status: '',
				phone: '',
				createdAt: '',
				checkTime: '',
				countryText: '',
				loadingText: 'Cargando registros',
				valid: true,
				disableForm: true,
				descriptionRules: [
					(v) => !!v || 'La descripción es requerida',
					(v) => v.length <= 100 || 'La descripción debe poser menos de 100 caracteres',
				],
				chartOptions: {
					chart: {
						type: 'bar',
						stacked: true,
						width: '100%',
						toolbar: {
							show: true,
							offsetX: 0,
							offsetY: 0,
							tools: {
								download: `<i aria-hidden="true" class="v-icon notranslate mdi mdi-download theme--light"></i>`,
								selection: true,
								zoom: true,
								zoomin: true,
								zoomout: true,
								pan: true,
								reset: true | '<img src="/static/icons/reset.png" width="20">',
								customIcons: [],
							},
							export: {
								csv: {
									filename: undefined,
									height: 500,
									columnDelimiter: ',',
									headerCategory: 'category',
									headerValue: 'value',
									dateFormatter(timestamp) {
										return new Date(timestamp).toDateString()
									},
								},
								svg: {
									filename: undefined,
								},
								png: {
									filename: undefined,
								},
							},
							autoSelected: 'zoom',
							fontFamily: 'inherit',
						},
					},
					plotOptions: {
						bar: {
							horizontal: false,
						},
					},
					title: {
						text: '',
						align: 'center',
					},
					xaxis: {
						categories: [],
						title: {
							text: '',
						},
						labels: {
							formatter: function (val) {
								return val
							},
						},
					},
					yaxis: {
						title: {
							text: '',
						},
					},
					tooltip: {
						y: {
							formatter: (val) => {
								return val
							},
						},
					},
					fill: {
						opacity: 1,
					},
					legend: {
						position: 'top',
						horizontalAlign: 'center',
						offsetX: 0,
					},
				},
				chartSeries: [],
				index: 0,
			}
		},
		created() {
			this.handleSelectedCountry()
			this.getFormRequests()
			this.getCountries()
			this.getChartData()
		},
		computed: {
			selectedCountry() {
				if (localStorage.getItem('selectedCountry')) {
					return JSON.parse(localStorage.getItem('selectedCountry'))
				} else {
					return {}
				}
			},
			roles() {
				if (this.$session.get('roles')) {
					return this.$session.get('roles').map((role) => {
						return role.name
					})
				} else {
					return []
				}
			},
		},
		methods: {
			handleSelectedCountry() {
				if (
					!this.roles.includes('super_admin') &&
					localStorage.getItem('selectedCountry')
				) {
					this.filters.country = this.selectedCountry
				}
			},
			cleanFilters() {
				this.filters = {
					search: '',
					service: null,
					status: null,
					date: {
						endAt: null,
						startAt: null,
					},
					country: this.roles.includes('super_admin') ? null : this.selectedCountry,
				}
			},
			getChartData() {
				let dateString = ''
				if (this.filters.date.startAt && this.filters.date.endAt) {
					dateString =
						'&startAt=' + this.filters.date.startAt + '&endAt=' + this.filters.date.endAt
				}
				axios
					.get(
						process.env.VUE_APP_API_DIRECTORY +
							'graphics/form-requests-by-status?' +
							dateString
					)
					.then((response) => {
						this.chartSeries = response.data.series
						this.chartSeries.forEach((element) => {
							element.name = this.statusSelect.find(
								(status) => status.id === element.name
							).name
						})
						this.chartOptions.xaxis.categories = response.data.labels
						this.index++
					})
			},
			async getFormRequests() {
				this.loading = true
				let queryParams = ''
				let orderString = ''
				let countryString = ''
				let searchString = ''
				let serviceString = ''
				let statusString = ''
				if (this.orderBy.order && this.orderBy.by) {
					orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
				}
				if (this.filters.country && this.filters.country.id) {
					countryString = '&country_id=' + this.filters.country.id
				}
				if (this.filterActivated) {
					if (this.filters.search !== '') {
						searchString = '&search=' + this.filters.search
					}
					if (this.filters.service && this.filters.service.name !== 'Todos') {
						serviceString = '&service=' + this.filters.service.name
					}
					if (this.filters.status && this.filters.status.id) {
						statusString = '&status=' + this.filters.status.id
					}
				}
				queryParams =
					searchString + serviceString + statusString + countryString + orderString
				axios
					.get(
						process.env.VUE_APP_API_DIRECTORY +
							'form-requests?page=' +
							this.page +
							queryParams
					)
					.then((response) => {
						this.items = response.data.data
						if (this.items.length < 1) {
							this.loadingText = 'No hay registros'
						}
						this.totalItems = response.data.total
						this.itemsPerPage = response.data.per_page
						if (response.data.from) {
							this.page = response.data.current_page
							this.pages = response.data.last_page
						} else {
							this.page = 1
							this.pages = 1
						}
						this.items.forEach((x) => {
							switch (x.status) {
								case 'pending':
									x.statusText = 'Por atender'
									break
								case 'verified':
									x.statusText = 'Atendida'
									break
								case 'rejected':
									x.statusText = 'Rechazada'
									break
								case 'canceled':
									x.statusText = 'Cancelada'
									break
								default:
									x.statusText = ''
									break
							}
							x.dateText = new Date(x.created_at).toLocaleDateString('es-VE', {
								// weekday: 'short',
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
								timeZone: 'UTC',
							})
							x.userFullName = x.name + ' ' + x.lastname
						})
						this.loading = false
					})
			},

			countryName({ name, iso2 }) {
				if (name && iso2) {
					return `${name} (${iso2})`
				} else if (name) {
					return name
				}
			},

			async getCountries() {
				this.loading = true
				axios
					.get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=300')
					.then((response) => {
						this.countries = response.data
						this.loading = false
					})
			},

			async handleEdit(item) {
				this.flow = 'edit'
				this.item = item
				if (this.item.user) {
					this.item.user.fullname =
						this.item.user.name +
						' ' +
						this.item.user.lastname +
						' | ' +
						this.item.user.email
				}

				this.userId = this.item.user_id
				this.country = this.item.country.id
				this.companyId = this.item.company_id
				this.checkerId = this.item.checker_id
				this.name = this.item.name
				this.lastname = this.item.lastname
				this.documentNumber = this.item.document_number
				this.service = this.item.service
				this.businessName = this.item.business_name
				this.businessType = this.item.business_type
				this.message = this.item.message
				this.email = this.item.email
				this.status = this.item.status
				this.phone = this.item.phone
				this.createdAt = new Date(this.item.created_at)
				this.checkTime = this.item.check_time ? this.item.check_time.split('T')[0] : null

				this.$modal.show('crudModal')
			},

			validate() {
				this.$refs.form.validate()
			},

			reset() {
				this.$refs.form.reset()
			},

			async saveItem() {
				this.loading = true
				this.selection = this.selection ? 1 : 0
				axios
					.patch(
						process.env.VUE_APP_API_DIRECTORY + 'form-requests/' + this.item.id,
						{
							user_id: this.userId,
							country_id: this.country,
							company_id: this.companyId,
							checker_id: this.checkerId,
							name: this.name,
							lastname: this.item.lastname,
							document_number: this.documentNumber,
							service: this.service,
							business_name: this.businessName,
							business_type: this.businessType,
							message: this.message,
							email: this.email,
							status: this.status,
							phone: this.phone,
						},
						this.config
					)
					.then((response) => {
						this.getFormRequests()
						this.$modal.hide('crudModal')
					})
					.catch((error) => {})
					.finally(() => (this.loading = false))
			},

			async deleteItem(item) {
				this.loading = true
				axios
					.delete(process.env.VUE_APP_API_DIRECTORY + 'form-requests/' + item.id)
					.then((response) => {
						this.page = 1
						this.items = []
						this.getFormRequests()
					})
					.catch((error) => {})
					.finally(() => (this.loading = false))
			},

			async pagination(page) {
				this.page = page
				this.items = []
				await this.getFormRequests()
			},
		},
	}
</script>
<style lang="scss">
	@import '@/assets/scss/cruds/_crudStyles.scss';

	.chart-box {
		height: 350px;
		overflow-x: auto;
	}
</style>
